<app-loading-spinner *ngIf="pageCreation"></app-loading-spinner>

<section class="h-screen overflow-visible">
  <div class="w-full md:w-6/12 lg:w-4/12 my-3 mx-auto font-mont" *ngFor="let payment of showPaymentPage">
    <div class="flex flex-col items-center justify-center w-full">
      <div class="bg-zestBg rounded-lg p-4 flex justify-start items-start w-full">
        <div class="w-20 h-20 mr-4">
          <img *ngIf="payment?.imageData?.image_source; else default" src="{{ payment?.imageData?.image_source }}"
            alt="page_logo" class="rounded-lg w-full object-center" />
          <ng-template #default>
            <img src="../../../../../assets/img/paymentpagedefault.png" alt="page_logo"
              class="rounded-lg w-full object-center" />
          </ng-template>
        </div>
        <div>
          <p class="font-semibold text-zestBlack text-xl">
            {{ payment?.subPageName }}
          </p>
          <p class="text-zestBlack">
            by
            <span class="text-zestBlack font-medium">{{
              businessName
              }}</span>
          </p>
        </div>
      </div>

      <div class="">
        <!-- <p
          class="text-center text-zestLabel font-medium text-base"
          *ngIf="payment.fixed"
        >
          Amount:
          <span class="text-2xl font-semibold text-[#4E489D]">{{
            payment.amount | currency : "₦ "
          }}</span>
        </p> -->
        <form (ngSubmit)="onSubmit($event)" [formGroup]="formValues" class="px-4 mr-0 lg:mr-3">
          <div class="pt-10">
            <div class="flex pb-6 justify-between">
              <div class="grow w-1/2 mr-6">
                <label class="block py-2 font-normal leading-4 text-sm tracking-[0.01em] text-zestGrey">
                  First Name
                </label>
                <input
                  class="bg-white w-full h-[56px] outline-hidden placeholder-zestPlaceholder rounded border-[1px] border-solid border-[#c1c7d0] p-4 focus:border-zestGreen focus:ring-zestGreen"
                  type="text" placeholder="First name" name="firstName" formControlName="firstName" required />
                <span class="text-red-500 pt-1" *ngIf="
                    !formValues.get('firstName')?.valid &&
                    formValues.get('firstName')?.touched
                  ">*First name is required</span>
              </div>
              <div class="grow w-1/2">
                <label class="block py-2 font-normal leading-4 text-sm tracking-[0.01em] text-zestGrey">
                  Last Name
                </label>
                <input
                  class="bg-white w-full h-[56px] outline-hidden placeholder-zestPlaceholder rounded border-[1px] border-solid border-[#c1c7d0] p-4 focus:border-zestGreen focus:ring-zestGreen"
                  type="text" placeholder="Last name" name="lastName" formControlName="lastName" required />
                <span class="text-red-500 pt-1" *ngIf="
                    !formValues.get('lastName')?.valid &&
                    formValues.get('lastName')?.touched
                  ">*Last name is required</span>
              </div>
            </div>
            <div class="grow w-full mr-3">
              <label class="block pb-2 font-normal leading-4 text-sm tracking-[0.01em] text-zestGrey">
                Email
              </label>
              <input
                class="bg-white w-full h-[56px] outline-hidden placeholder-zestPlaceholder rounded border-[1px] border-solid border-[#c1c7d0] p-4 focus:border-zestGreen focus:ring-zestGreen"
                type="email" placeholder="Enter email address" name="email" formControlName="email" required />
              <div class="text-red-500 pt-1" *ngIf="
                  !formValues.get('email')?.valid &&
                  formValues.get('email')?.touched
                ">
                <span *ngIf="formValues.get('email')?.errors?.required">*Email address is required</span>
                <span *ngIf="formValues.get('email')?.errors?.email">*Email address is invalid</span>
              </div>
            </div>
            <div formArrayName="response" *ngIf="payment?.fields?.length > 0">
              <div [formGroupName]="i" class="grow w-full mr-3"
                *ngFor="let control of response.controls; let i = index">
                <label class="block pb-2 pt-6 font-normal leading-4 text-sm tracking-[0.01em] text-zestGrey">
                  {{ payment?.fields[i] }}
                </label>
                <input
                  class="bg-white w-full h-[56px] outline-hidden placeholder-zestPlaceholder rounded border-[1px] border-solid border-[#c1c7d0] p-4 focus:border-zestGreen focus:ring-zestGreen"
                  type="text" placeholder="Enter {{ payment?.fields[i] }}" [name]="payment?.fields[i]"
                  [formControlName]="payment?.fields[i]" required />
                <span class="text-red-500 pt-1" *ngIf="
                  (!formValues.controls.response.get(i.toString())?.get(payment?.fields[i])?.valid &&
                  formValues.controls.response.get(i.toString())?.get(payment?.fields[i])?.touched) || formValues.controls.response.get(i.toString())?.get(payment?.fields[i])?.value === ''
                  ">*This field is required</span>
              </div>
            </div>
          </div>
          <div class="grow w-full mr-3">
            <label class="block pt-6 pb-2 font-normal leading-4 text-sm tracking-[0.01em] text-zestGrey">
              Amount
            </label>
            <div class="">
              <div class="relative rounded-md">
                <div class="absolute inset-y-0 left-0 flex items-center">
                  <label for="metricUnit" class="sr-only">Weight</label>
                  <select id="amount_sign" name="amount_sign" autocomplete="amount_sign"
                    class="h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-8 focus:border-zestGreen focus:ring-zestGreen sm:text-sm">
                    <option>NGN</option>
                  </select>
                </div>
                <input *ngIf="payment.fixed; else isNotFixed"
                  class="bg-white w-full h-[56px] font-semibold text-zestBlack disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 placeholder-zestPlaceholder outline-hidden rounded border border-solid border-[#c1c7d0] pl-[5.3rem] focus:border-zestGreen focus:ring-zestGreen"
                  type="number" min="0" placeholder="0.00" disabled [value]="payment?.amount" name="amount" />
                <ng-template #isNotFixed>
                  <input
                    class="bg-white w-full h-[56px] disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 placeholder-zestPlaceholder outline-hidden rounded border border-solid border-[#c1c7d0] pl-[5.3rem] focus:border-zestGreen focus:ring-zestGreen"
                    type="number" min="0" placeholder="0.00" name="amount" step="100" formControlName="amount" />
                </ng-template>
              </div>
            </div>
            <span class="text-red-500 pt-1" *ngIf="
                !formValues.get('amount')?.valid &&
                formValues.get('amount')?.touched
              ">*Amount is required</span>
          </div>

          <button type="submit"
            class="rounded-lg text-white bg-zestGreen my-14 py-3 w-full cursor-pointer disabled:bg-zestPlaceholder font-semibold text-lg">
            Proceed to Payment
          </button>
        </form>
      </div>
    </div>
  </div>
  <div class="text-center bg-zestBg py-12">
    <footer class="text-zestBlack font-medium text-base pb-4 font-mont">
      This payment will be received by {{ businessName }}
    </footer>
    <div class="hidden lg:flex mt-10 items-center font-mont justify-center">
      <img class="pr-2 w-8" src="../../../assets/svg/Lock.svg" alt="email" />
      <small class="block text-zestLabel font-semibold text-sm">Secured By
        <a href="{{ setupStoreLink }}" target="_blank" rel="noopener" class="text-zestGreen">Zest</a></small>
    </div>
  </div>
</section>