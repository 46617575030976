export class DownloadHelper{
    static fileDownload(title: string, type:string,data:any) {
          let a = document.createElement('a');
          let file,
            fileURL

          switch (type) {
            case 'pdf':
              file = new Blob([data], { type: 'application/pdf' });
              fileURL = URL.createObjectURL(file);
              a.href = fileURL;
              a.target = '_blank';
              a.download = `${
                title +
                new Date().toDateString() +
                ' ' +
                new Date().toLocaleTimeString()
              }.pdf`;
              document.body.appendChild(a);
              a.click();
              break;
            case 'csv':
              file = new Blob([data], { type: 'application/csv' });
              fileURL = URL.createObjectURL(file);
              a.href = fileURL;
              a.target = '_blank';
              a.download = `${
                title +
                new Date().toDateString() +
                ' ' +
                new Date().toLocaleTimeString()
              }.csv`;
              document.body.appendChild(a);
              a.click();
              break;
            case 'excel':
              file = new Blob([data], { type: 'application/xlsx' });
              fileURL = URL.createObjectURL(file);
              a.href = fileURL;
              a.target = '_blank';
              a.download = `${
                title +
                new Date().toDateString() +
                ' ' +
                new Date().toLocaleTimeString()
              }.xlsx`;
              document.body.appendChild(a);
              a.click();
              break;
            default:
              return;
          }
    }
}