<div class="table-control">
  <div>
    <p>Showing {{ start }}-{{ end }} of {{ total }}</p>
  </div>
  <div>
    <ng-container *ngIf="id">
      <!-- <pagination-controls
        [maxSize]="7"
        (pageChange)="paged($event)"
        class="my-pagination"
      ></pagination-controls>
    </ng-container>
    <ng-container *ngIf="!id">
      <pagination-controls
        [maxSize]="7"
        [id]="id"
        (pageChange)="paged($event)"
        class="my-pagination"
      ></pagination-controls> -->
    </ng-container>
  </div>
</div>
