import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PaymentService } from '../shared/services/payment.service';
import { CustomValidator } from '../shared/ui-elements/validators/custom-validator';
import { SnackBarService } from './../shared/services/snackbar.service';
declare var ZestPayPop: any;

@Component({
  selector: 'app-client-payment-details',
  templateUrl: './client-payment-details.component.html',
  styleUrls: ['./client-payment-details.component.scss'],
})
export class ClientPaymentDetailsComponent implements OnInit {
  constructor(
    public fb: FormBuilder,
    private http: HttpClient,
    public route: ActivatedRoute,
    private router: Router,
    public snackBar: SnackBarService,
    private payment: PaymentService
  ) {
    this.setupStoreLink = environment.SETUP_ZEST_PROFILE_URL;
    this.formValues = this.fb.group({
      email: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      amount: [null, Validators.required],
      response: this.fb.array([]),
      refNumber: [''],
      transactionStatus: [''],
    });
  }

  showPaymentPage: any[] = [];
  loading: boolean = false;
  pageCode: string = JSON.parse(localStorage.getItem('pageCode')!);
  count!: number;
  pageData: any;
  pageCreation: boolean = false;
  pageSubmit: boolean = false;
  transactionData: any;
  transactionResponse: any;
  businessEmail: string | any;
  businessName: string | any;
  bizName: string | any;
  specialInput: any[] = [];
  disable: boolean = false;
  formValues: FormGroup;

  singlePaymentPage!: {
    subPageCode: string;
  };

  setupStoreLink!: string;
  publicKey!: string;

  getPublicKey(merchant: string) {
    this.payment.getKey(merchant).subscribe({
      next: (res: any) => {
        this.publicKey = res;
      },
      error: (err) => {},
    });
  }

  getBusinessName(merchant: string) {
    this.payment.getMerchant(merchant).subscribe({
      next: (res: any) => {
        this.businessName = res;
      },
      error: (err) => {},
    });
  }

  get response() {
    return this.formValues.get('response') as FormArray;
  }

  displayExtraInfo() {
    const fields = this.pageData.data.fields;
    const newFieldsResponse: any[] = [];
    fields.forEach((field: any) => {
      newFieldsResponse.push({ name: field, value: '' });
    });

    newFieldsResponse.forEach((field: any) => {
      const groupField = this.fb.group({
        [field.name]: [field.value, Validators.required],
      });
      this.response.push(groupField);
    });
  }

  getSinglePaymentPage() {
    this.pageCreation = true;
    this.payment.singlePayPage(this.singlePaymentPage.subPageCode).subscribe({
      next: (res: any) => {
        this.pageData = res;
        this.displayExtraInfo();

        this.disable = this.pageData.data.fixed;
        this.showPaymentPage.push(this.pageData.data);
        this.getPublicKey(this.showPaymentPage[0].page.merchantId);
        this.getBusinessName(this.showPaymentPage[0].page.merchantId);
        if (this.disable)
          this.formValues.controls.amount.setValue(this.pageData.data.amount);

        this.pageCreation = false;
      },
      error: (err) => {
        this.snackBar.openSnackBar(`Unable to complete`, 'error-snackbar');
        this.pageCreation = false;
      },
    });
  }

  payWithZest() {
    let trxRef: string = '';
    let status: string = '';
    let handler = ZestPayPop.setup({
      key: this.publicKey, // Replace with your public key
      email: this.formValues.value.email,
      amount:
        this.showPaymentPage[0].amount * 100 ||
        this.formValues.value.amount! * 100,

      metadata: {
        source: 'Payment pages',
      },
      onClose: (response: any) => {},
      callback: (response: any) => {
        trxRef = response.reference;
        status = response.status;
        this.formValues.controls.transactionStatus.setValue(status);
        this.formValues.controls.refNumber.setValue(trxRef);
        this.postResponse();
      },
    });
    handler.openIframe();
  }

  postResponse() {
    this.pageSubmit = true;
    this.payment
      .sendResponse(this.singlePaymentPage.subPageCode, this.formValues.value)
      .subscribe({
        next: (res: any) => {
          this.router.navigate([
            'pay',
            this.singlePaymentPage.subPageCode,
            'success',
          ]);
          this.pageSubmit = false;
        },
        error: (err) => {
          this.snackBar.openSnackBar(`Unable to complete`, 'error-snackbar');
          this.pageSubmit = false;
        },
      });
  }

  onSubmit(e: any) {
    e.preventDefault();
    if (this.formValues.invalid) {
      CustomValidator.validateAllFormFields(this.formValues);
      return;
    }
    this.payWithZest();
  }

  ngOnInit(): void {
    this.singlePaymentPage = {
      subPageCode: this.route.snapshot.params['id'],
    };
    this.getSinglePaymentPage();
    window.scrollTo(0, 0);
  }
}
