import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientPaymentDetailsComponent } from './client-payment-details/client-payment-details.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { SuccessScreenComponent } from './success-screen/success-screen.component';
import { InvoicePaymentComponent } from './invoice-payment/invoice-payment.component';

const routes: Routes = [
  {
    path: ':id',
    component: LandingPageComponent,
    title: 'Payment Pages',
    pathMatch: 'full',
  },
  {
    path: 'pay/:id',
    component: ClientPaymentDetailsComponent,
    title: 'Payment Page Details',
    children: [],
  },
  {
    path: 'invoice/:id',
    title: 'Invoice Payment',
    component: InvoicePaymentComponent,
  },
  {
    path: 'invoice/pay/success',
    title: 'Invoice Payment Success',
    component: SuccessScreenComponent,
  },
  {
    path: 'pay/:id/success',
    title: 'Payment Pages',
    component: SuccessScreenComponent,
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
    component: LandingPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
