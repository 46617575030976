import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { MaterialModule } from './material/material.module';
import { CustomAlertComponent } from './components/custom-alert/custom-alert.component';
import { NativeButtonComponent } from './ui-elements/native-button/native-button.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
// import { HeaderComponent } from './components/header/header.component';
import { FeatureCardComponent } from './ui-elements/feature-card/feature-card.component';
import { ProgressSpinnerComponent } from './ui-elements/progress-spinner/progress-spinner.component';
import { DragDropFileUploadDirective } from './directives/drag-drop-file-upload.directive';
import { PaginationComponent } from './ui-elements/pagination/pagination.component';
import { BaseTableComponent } from './ui-elements/base-table/base-table-component';
import { PipesModule } from './pipes/pipes.module';
import { MatRadioModule } from '@angular/material/radio';
import { SnackBarComponent } from './ui-elements/snackbar/snackbar.component';
import { EmptyStateComponent } from './ui-elements/empty-state/empty-state.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
// import { ClipboardModule } from 'ngx-clipboard';

@NgModule({
  declarations: [
    CustomAlertComponent,
    NativeButtonComponent,
    LoadingSpinnerComponent,
    // HeaderComponent,
    FeatureCardComponent,
    ProgressSpinnerComponent,
    DragDropFileUploadDirective,
    PaginationComponent,
    BaseTableComponent,
    SnackBarComponent,
    EmptyStateComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedRoutingModule,
    MaterialModule,
    PipesModule,
    MatRadioModule,
    MatCheckboxModule,
  ],
  exports: [
    CustomAlertComponent,
    NativeButtonComponent,
    LoadingSpinnerComponent,
    // HeaderComponent,
    FeatureCardComponent,
    ProgressSpinnerComponent,
    MaterialModule,
    // NgxMatIntlTelInputModule,
    // NgxOtpInputModule,
    NgxPaginationModule,
    DragDropFileUploadDirective,
    PaginationComponent,
    BaseTableComponent,
    PipesModule,
    SnackBarComponent,
    // AvatarModule,
    MatRadioModule,
    EmptyStateComponent,
    MatCheckboxModule,
    // ClipboardModule,
  ],
})
export class SharedModule {}
