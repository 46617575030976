import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-success-screen',
  templateUrl: './success-screen.component.html',
  styleUrls: ['./success-screen.component.scss'],
})
export class SuccessScreenComponent implements OnInit {
  constructor(private route: ActivatedRoute, public router: Router) {}

  code: string = '';
  pageCode: string = '';

  goBackHome() {
    this.router.navigate(['/', this.pageCode]);
  }

  ngOnInit(): void {
    this.code = this.route.snapshot.params['id'];
    this.pageCode = JSON.parse(sessionStorage.getItem('code')!)
      ? JSON.parse(sessionStorage.getItem('code')!)
      : '';
    window.scrollTo(0, 0);

    console.log(this.router.url);
  }
}
