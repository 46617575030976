<div class="custom-button">
  <button
    [class]="buttonClass"
    [type]="type"
    [ngClass]="{
      'btn-loading': submitting,
      'btn-block': block,
      disabled: disabled
    }"
    [disabled]="disabled"
  >
    <span>{{ name }}</span>
  </button>
</div>
