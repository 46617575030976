import { Component } from '@angular/core';
import { ApiResponse } from 'src/app/modules/core/api/api-response';
import { PagedResult } from 'src/app/modules/core/api/paged-result.model';
import { DownloadHelper } from 'src/app/modules/core/helpers/file-download-helper';

@Component({
  selector: 'app-base-table-component',
  template: '',
})
export class BaseTableComponent<T> {
  public start!: number;
  public end!: number;
  public total: number = 0;
  public element!: number;
  public page = 0;
  public pageSize = 15;
  public emptyData!: boolean;
  public loadingData!: boolean;
  public data: Array<T> = [];
  public pagedResult!: PagedResult<any>;
  public loadDataCallBack: any;
  public paginator: any;
  public loadFileDownload: any;
  public fileType!: string;
  public fileTitle!: string;
  constructor() {
    this.paginator = {
      itemsPerPage: this.pageSize,
      currentPage: 0,
      totalItems: 0,
    };
  }

  public loadData() {
    this.emptyData = false;
    this.loadingData = true;

    this.loadDataCallBack(this.page, this.pageSize).subscribe({
      next: (response: ApiResponse) => {
        this.loadingData = false;
        if (response.success) {
          if (response.data) {
            this.pagedResult = response.data;
            this.data = response.data.content;
            this.loadingData = false;
            // console.log('Data', this.data);
            if (this.data && this.data.length < 1) {
              this.emptyData = true;
              this.start = 0;
              this.end = 0;
              this.total = this.pagedResult.totalItems;
            } else {
              this.start = this.page * this.pageSize + 1;
              this.end = this.page * this.pageSize + this.data.length;
              this.total = this.pagedResult.totalItems;
              // console.log('START', this.start, this.end);
              this.element = this.pagedResult.totalPages;
              this.paginator.totalItems = this.pagedResult.totalItems;
              this.paginator.numberOfElements = this.pagedResult.totalPages;
            }
          } else {
            this.emptyData = true;
          }
        }
      },
      error: (err: ApiResponse) => {
        this.loadingData = false;
      },
    });
  }
  public downloadFile() {
    this.loadingData = true;
    this.loadFileDownload(this.fileType).subscribe({
      next: (response: any) => {
        // console.log(response);
        this.loadingData = false;
        DownloadHelper.fileDownload(this.fileTitle, this.fileType, response);
      },
      error: (err: any) => {
        this.loadingData = false;
        // console.log(err, 'err');
      },
    });
  }

  public pageChanged(pageInfo: number) {
    this.page = pageInfo - 1;
    this.paginator.currentPage = pageInfo;
    this.loadData();
  }
}
