import { NgModule } from '@angular/core';
import { CustomDatePipe } from './custom-date.pipe';
import { MajorAmountPipe } from './major-amount.pipe';
import { OrderByPipe } from './order-by.pipe';
import { TimeDiffPipe } from './time-left.pipe';
import { TimePipe } from './time.pipe';
import { TruncatePipe } from './truncate.pipe';

/**
 * Created by Stephen on 04/08/2022.
 */

@NgModule({
  declarations: [
    CustomDatePipe,
    TruncatePipe,
    MajorAmountPipe,
    OrderByPipe,
    TimeDiffPipe,
    TimePipe,
  ],
  exports: [
    CustomDatePipe,
    TruncatePipe,
    MajorAmountPipe,
    OrderByPipe,
    TimeDiffPipe,
    TimePipe,
  ],
})
export class PipesModule {}
