<section class="px-2 pt-10 lg:pt-20 lg:px-0 font-mont flex flex-col justify-center items-center">
  <div class="flex flex-col justify-center items-center my-auto">
    <div class="w-20 h-20 bg-[#F6FEF9] rounded-full flex justify-center items-center">
      <img src="../../assets/svg/tick-square.svg" alt="success" />
    </div>
    <p *ngIf="router.url !== '/invoice/pay/success'"
      class="pt-10 text-2xl font-semibold tracking-tighter-[0.48px] text-center">
      Thank you for your payment!
    </p>
    <p *ngIf="router.url !== '/invoice/pay/success'" class="pt-1 text-center">
      Sit tight! You will receive confirmation details<br class="hidden lg:block" />
      of your order as soon as the merchant confirms it.
    </p>
    <p *ngIf="router.url === '/invoice/pay/success'"
      class="pt-10 text-2xl font-semibold tracking-tighter-[0.48px] text-center">
      Thank you for your payment!
    </p>
    <p *ngIf="router.url === '/invoice/pay/success'" class="pt-1 text-center">
      We have sent your receipt to your email<br class="hidden lg:block" />
    </p>
    <button *ngIf="router.url !== '/invoice/pay/success'" (click)="goBackHome()"
      class="bg-zestGreen text-white text-sm lg:text-base py-4 mt-10 rounded-lg px-8 lg:px-20 flex justify-center items-center">
      Go back to Payment Page
    </button>
  </div>
</section>
