<app-loading-spinner *ngIf="pageCreation"></app-loading-spinner>
<section *ngIf="pageFound" class="flex flex-col justify-center items-center font-mont overflow-y-visible">
  <div class=""></div>
  <p class="pt-20 pb-6 font-medium text-lg lg:text-[28px] lg:leading-10">
    Select what you want to pay for
  </p>
  <div class="grid gap-6 sm:grid-cols-1 lg:auto-cols-auto" [ngClass]="{
      'md:grid-cols-2': paymentPages.length >= 2,
      'lg:grid-cols-4': paymentPages.length >= 4
    }">
    <div *ngFor="let paymentPage of paymentPages | paginate
    : {
        itemsPerPage: 8,
        currentPage: currentPage
      };" (click)="selectPage(paymentPage.subpageCode)" [ngClass]="{
        'border-zestGreen bg-zestGreenLight': selectedPage === paymentPage.subpageCode, 'bg-zestNeutral': selectedPage !== paymentPage.subpageCode
      }"
      class="border cursor-pointer hover:border-zestGreen rounded-lg w-48 bg-zestNeutral flex flex-col justify-center items-center px-5 py-10">
      <div class="w-36">
        <div class="w-full">
          <img *ngIf="paymentPage?.imageData?.image_source; else default"
            src="{{ paymentPage?.imageData?.image_source }}" alt="page_link_logo"
            class="rounded-full w-14 h-14 mx-auto object-center" />
          <ng-template #default>
            <img src="../../../../../assets/img/paymentpagedefault.png" alt="page_link_logo"
              class="rounded-full w-14 h-14 mx-auto object-center" />
          </ng-template>
        </div>
        <p class="my-10 text-center font-medium text-lg pt-10">
          {{ paymentPage?.subPageName }}
        </p>
      </div>
    </div>
  </div>
  <div class="grid gap-2 sm:grid-cols-1 lg:grid-cols-3 lg:gap-10 place-items-center">
    <p class="pb-2 pt-2 md:pb-0 pl-5 col-span-2 place-self-start">
      Showing page {{ currentPage }} of {{ lastpage }}
    </p>
    <pagination-controls class="custom-pagination items-center pl-6 lg:pl-0 mt-2 place-self-end" previousLabel="Prev"
      (pageChange)="currentPage = $event"></pagination-controls>
  </div>
  <button [disabled]="disable" (click)="showPaypage()"
    class="rounded-lg text-white bg-zestGreen my-14 py-3 w-[297px] cursor-pointer disabled:bg-zestPlaceholder">
    Proceed
  </button>

  <div class="hidden lg:flex mt-10 items-center">
    <img class="pr-2 w-8" src="../../../assets/svg/Lock.svg" alt="email" />
    <small class="block text-zestLabel font-semibold text-sm">Secured By
      <a href="{{ setupStoreLink }}" target="_blank" rel="noopener" class="text-zestGreen">Zest</a></small>
  </div>
</section>
<section class="flex flex-col justify-center items-center font-mont overflow-y-visible" *ngIf="!pageFound">
  <section class="px-2 pt-10 lg:pt-20  lg:px-0 font-mont flex flex-col justify-center items-center">
    <div class="flex flex-col justify-center items-center my-auto">
      <!-- <div class="w-20 h-20 bg-[#F6FEF9] rounded-full flex justify-center items-center">
        <img src='../../assets/svg/tick-square.svg' alt="failure" />
      </div> -->
      <p class="pt-10 text-2xl font-semibold tracking-tighter-[0.48px] text-center">Payment page not found</p>
      <p class="pt-1 text-center">Please check the link and try again<br class="hidden lg:block" /></p>
    </div>
  </section>
  <!-- <p class="pt-20 pb-6 font-medium text-lg lg:text-[28px] lg:leading-10">Payment page not found</p>
  <div class="grid gap-6 sm:grid-cols-1 lg:auto-cols-auto">

  </div> -->
</section>