import { Injectable } from '@angular/core';

@Injectable()
export class MoneyHelper {
  public static formatMoneyShortForm(amount: number): string {
    if (amount >= 1000000000) {
      return '₦' + (amount / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
    }
    if (amount >= 1000000) {
      return '₦' + (amount / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (amount >= 1000) {
      return '₦' + (amount / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return '₦' + amount.toString();
  }

  public static formatMoney(amount: number): string {
    return '₦' + amount.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  }

  public static formatToDecimal(amount: string): number {
    return parseFloat(amount.toString().replace(/[₦,]+/g, ''));
  }

  public static toMinorAmount(amount: number): number {
    return +(amount * 100).toFixed();
  }

  public static toMajorAmount(amount: any): any {
    if (amount !== 0 && (!amount || isNaN(amount))) {
      return '';
    } else {
      return parseFloat(amount) / 100;
    }
  }

  public static normalizeInputValue = (val: string) => {
    if (typeof val === 'string') {
      val = val.split(',').join('');
    }
    // val = val.replace(/^0+/, "").replace(/\D/g, ""); // This should remove leading zeros as well as non-numeric characters
    return val;
  };
}
