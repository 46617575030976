import { PipeTransform, Pipe } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { MoneyHelper } from '../../modules/core/helpers/money.helper';

/**
 * Created by Stephen on 5/10/2022.
 */

@Pipe({ name: 'majorAmount' })
export class MajorAmountPipe extends DecimalPipe implements PipeTransform {
  override transform(value: any): any {
    return MoneyHelper.toMajorAmount(value);
  }
}
