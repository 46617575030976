import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent implements OnInit {

  @Input() obj!: EmptyStateModel;
  
  constructor() { }

  ngOnInit(): void {
  }

}

export class EmptyStateModel {
  public title: string;
  public showIcon?: boolean;
  public subtitle?: string;
  public linkText?: string;
  public linkText2?: string;
  public linkUrl?: string;
  public linkUrl2?: string;
  public iconUrl?: string;
  public callback?: boolean;

  constructor(obj?: any) {
    this.title = obj && obj.title;
    this.showIcon = (obj && obj.showIcon) || null;
    this.subtitle = (obj && obj.title) || null;
    this.linkText = (obj && obj.linkText) || null;
    this.linkText2 = (obj && obj.linkText2) || null;
    this.linkUrl = (obj && obj.linkUrl) || null;
    this.linkUrl2 = (obj && obj.linkUrl2) || null;
    this.iconUrl = (obj && obj.iconUrl) || null;
    this.callback = (obj && obj.callback) || false;
  }
}