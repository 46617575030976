import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'paymentPages';

  public loadScript() {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.src = environment.SDK_URL;
    script.defer = true;

    /* add your script to DOM */
    body.appendChild(script);
  }

  ngOnInit(): void {
    // this.loadScript();
  }
}
