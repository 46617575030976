import { Component, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'app-feature-card',
  templateUrl: './feature-card.component.html',
  styleUrls: ['./feature-card.component.scss']
})
export class FeatureCardComponent implements OnInit {

  @Input('title') title!: string;
  @Input('id') id: any;
  @Input('subtitle') subtitle: any;
  @Input('backgroundColor' ) backgroundColor!: string;
  @Input('routeName') routeName!: string;
  @Input('route') route!: (args: any) => void;
  constructor() { 
  }

  ngOnInit(): void {
    this.id  = JSON.parse(this.id)
  }

  onRoute(event: any) {
    this.route(event)
  }

}
