<app-loading-spinner *ngIf="pageCreation"></app-loading-spinner>
<!-- max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl mx-auto -->
<section *ngIf="!pageCreation && !showInvalid"
  class="h-screen overflow-y-visible flex justify-center items-center w-full">
  <main class="px-3 lg:px-0 grow" *ngIf="!success">
    <div class="w-full md:w-6/12 lg:w-3/12 my-3 mx-auto font-mont text-center">
      <p class="text-sm font-medium text-zestTertiary-500">{{invoiceDetails.merchantBusinessName}} has sent you an
        invoice of</p>
      <p class="text-2xl font-bold">{{invoiceDetails.total | currency : invoiceDetails.currency == 'NGN' ? "₦" : "$"}}
      </p>
      <p class="text-sm font-medium text-zestTertiary-500 pb-5">Due Date on {{invoiceDetails.dueDate.split(' ')[0]}}</p>
    </div>
    <div class="w-full md:w-8/12 lg:w-6/12 mx-auto font-mont">
      <div class="text-center text-base font-semibold bg-[#F2F4F7] p-5">Invoice Summary</div>
      <div class="p-5 bg-[#F9FAFB]">
        <div *ngFor="let items of invoiceDetails.invoiceItems">
          <div class="flex justify-between items-center pb-6">
            <p class="text-sm font-medium">{{items.item}} X {{items.quantity}}</p>
            <p class="text-sm font-medium">{{items.amount | currency : invoiceDetails.currency == 'NGN' ? "₦" : "$"}}
            </p>
          </div>
        </div>
        <div class="flex justify-between items-center pb-6">
          <p class="text-sm font-medium">Discount</p>
          <p class="text-sm font-medium">{{invoiceDetails.discountType == 'AMOUNT' ? (invoiceDetails.currency == 'NGN' ?
            "₦" :
            "$") : ''}}{{
            invoiceDetails.discount
            }}{{invoiceDetails.discountType == 'PERCENTAGE' ? '%' : ''}}
          </p>
        </div>
        <div class="flex justify-between items-center pb-6">
          <p class="text-sm font-medium">Tax (%)</p>
          <p class="text-sm font-medium">{{invoiceDetails.tax}}
          </p>
        </div>
        <hr class="text-zestLabel" />
        <div class="flex justify-between items-center pt-5">
          <p class="text-sm font-medium">Total</p>
          <p class="font-bold text-sm">{{invoiceDetails.total | currency : invoiceDetails.currency == 'NGN' ? "₦" :
            "$"}}</p>
        </div>
      </div>
      <button (click)="makePayment()"
        class="rounded-lg text-white bg-zestGreen my-14 py-3 lg:mx-auto w-full flex justify-center items-center lg:w-1/3 cursor-pointer disabled:bg-zestPlaceholder font-semibold text-lg">Make
        Payment</button>
    </div>
  </main>
  <section *ngIf="success" class="px-2 pt-10 lg:pt-20  lg:px-0 font-mont flex flex-col justify-center items-center">
    <div class="flex flex-col justify-center items-center my-auto">
      <div class="w-20 h-20 bg-[#F6FEF9] rounded-full flex justify-center items-center">
        <img src='../../assets/svg/tick-square.svg' alt="success" />
      </div>
      <p class="pt-10 text-2xl font-semibold tracking-tighter-[0.48px] text-center">Thank you for your payment!</p>
      <!-- <p class="pt-1 text-center">Sit tight! You will receive confirmation details<br class="hidden lg:block" /> of your
        order as soon as the merchant confirms it.</p> -->
    </div>
  </section>
</section>

<section *ngIf="!pageCreation && showInvalid"
  class="px-2 pt-10 lg:pt-20 lg:px-0 font-mont flex flex-col justify-center items-center">
  <div class="flex flex-col justify-center items-center my-auto">
    <div class="w-24 h-24 rounded-full flex justify-center items-center">
      <img src='../../assets/svg/info-circle.svg' alt="success" />
    </div>
    <p class="pt-10 text-2xl font-semibold tracking-tighter-[0.48px] text-center" *ngIf="!message">Invoice Paid</p>
    <p class="pt-10 text-2xl font-semibold tracking-tighter-[0.48px] text-center" *ngIf="message">Pay code invalid</p>
    <p class="pt-1 text-center" *ngIf="!message">The invoice has already been paid.
      <br class="hidden lg:block" />Please contact the merchant for further inquiries.
    </p>
    <p *ngIf="message">We checked but couldn’t find this invoice, please
      <br class="hidden lg:block" />contact the merchant for further inquiries.
    </p>
    <div class="w-full lg:w-[451px] h-20 mt-5 rounded-full flex justify-center items-center">
      <img src='../../assets/svg/checkout-cards.svg' alt="success" />
    </div>
  </div>
</section>