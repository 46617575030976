import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { SnackBarService } from './snackbar.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private snackBar: SnackBarService
  ) {}

  private baseUrl: string = `${environment.BASE_URL}/api/v1/paymentpages`;
  private invoiceUrl: string = `${environment.INVOICE_URL}/api/v1`;

  allPages(code: string) {
    return this.http.get(
      `${this.baseUrl}/subpage/filter/${code}?active=true&sort=dateCreated,desc`
    );
  }

  singlePayPage(code: string) {
    return this.http.get(`${this.baseUrl}/subpage?spc=${code}`);
  }

  sendResponse(code: string, data: any) {
    return this.http.post(
      `${this.baseUrl}/subpage/add-response?spc=${code}`,
      data
    );
  }

  getKey(code: string) {
    return this.http.get(`${this.baseUrl}/merchant?mc=${code}`, {
      responseType: 'text',
    });
  }

  getInvoice(request: any) {
    const url = `${this.invoiceUrl}/invoice/get?${request}`;
    return this.http.get(url);
  }

  invoiceStatus(request: any) {
    const url = `${this.invoiceUrl}/invoice/make-payment?${request}`;
    return this.http.get(url);
  }

  getMerchant(code: string) {
    return this.http.get(`${this.baseUrl}/merchantName?mc=${code}`, {
      responseType: 'text',
    });
  }

  updateInvoiceTrx(request: any) {
    return this.http.post(`${this.invoiceUrl}/transaction/pay`, request);
  }

  isPageCreated(merchantCode: string) {
    return this.http.get(`${this.baseUrl}/page/subpage?mc=${merchantCode}`);
  }
}
